<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_iabm.invitation_entry') }}
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link :to="`/training-e-learning-service/iabm/invitation?circular_memo_no=${data.circular_memo_no}&batch_no=${data.batch_no}`" :class="'btn btn_add_new'">{{ $t('elearning_iabm.invitation') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="loadIt">
                  <div id="form" >
                    <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                disabled
                                v-model="data.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('elearning_iabm.circular_org')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                disabled
                                v-model="data.circular_org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_type_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              disabled
                              v-model="data.training_type_id"
                              :options="trainingTypeList"
                              id="training_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="training_category_id"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              disabled
                              v-model="data.training_category_id"
                              :options="trainingCategoryList"
                              id="training_category_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="training_title_id"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              disabled
                              v-model="data.training_title_id"
                              :options="trainingTitleList"
                              id="training_title_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Batch" vid="batch_no" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="batch_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                disabled
                                v-model="data.batch_no"
                                :options="batchList"
                                id="batch_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option value=0>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Circular Memo No" vid="circular_memo_no"  rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_memo_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                disabled
                                v-model="data.circular_memo_no"
                                :options="circularList"
                                id="circular_memo_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option value='0'>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback d-block">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization Type" vid="profession_type" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="profession_type"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('elearning_config.organization_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="data.profession_type"
                                :options="orgTypeList"
                                id="profession_type"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12" v-if="data.profession_type === 1">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.organization')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="data.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Trainee Name" vid="personal_info_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="personal_info_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('elearning_tim.trainer_name') }} <span class="text-danger">*</span>
                            </template>
                            <v-select name="personal_info_id"
                              v-model="data.personal_info_id"
                              label="text"
                              :reduce="item => item.value"
                              :options= trainerList
                              :placeholder=" trainerLoading ? 'Loading....' : $t('globalTrans.select') "
                              :state="errors[0] ? false : (valid ? true : null)"
                              :filter-by="myFilter"
                            />
                            <div class="invalid-feedback d-block">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" v-if="checkUserAdd === 0">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                          <b-form @submit.prevent="handleSubmit(addInv)" @reset.prevent="reset" autocomplete="off">
                            <fieldset class="p-2 w-100">
                              <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.lesson') + ' ' + $t('globalTrans.entry')}}</legend>
                              <div class="p-2">
                                <b-row>
                                  <b-col lg="6" sm="6">
                                    <ValidationProvider name="Course Module"  vid="course_module_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="course_module_id"
                                          slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{$t('elearning_config.course_module')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          v-model="detailsFormData.course_module_id"
                                          :options="courseModuleList"
                                          id="course_module_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="6">
                                    <ValidationProvider name="Lesson"  vid="course_management_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="course_management_id"
                                          slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{$t('elearning_tim.lesson')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          v-model="detailsFormData.course_management_id"
                                          :options="courseList"
                                          id="course_management_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="6">
                                    <ValidationProvider name="Session Date"  vid="session_date" rules="required">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="session_date"
                                          slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.select_date') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                          plain
                                          v-model="detailsFormData.session_date"
                                          :options="trainingCalendar"
                                          id="session_date"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                        <div class="d-block invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="6">
                                    <ValidationProvider name="Start Time"  vid="start_time" rules="required">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="start_time"
                                          slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.start_time') }} <span class="text-danger">*</span>
                                        </template>
                                      <b-form-input
                                          type="time"
                                          v-model="detailsFormData.start_time"
                                          :placeholder="$t('globalTrans.select_date')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="6">
                                    <ValidationProvider name="End Time"  vid="end_time" rules="required">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="end_time"
                                          slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.end_time') }} <span class="text-danger">*</span>
                                        </template>
                                      <b-form-input
                                          type="time"
                                          v-model="detailsFormData.end_time"
                                          :placeholder="$t('globalTrans.select_date')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="6">
                                    <ValidationProvider name="Remarks"  vid="remarks_en">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="remarks_en"
                                          slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.remarks') }} {{ $t('globalTrans.en') }}
                                        </template>
                                      <b-form-input
                                          type="text"
                                          v-model="detailsFormData.remarks_en"
                                          :placeholder="$t('globalTrans.remarks')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="6">
                                    <ValidationProvider name="Remarks"  vid="remarks_bn">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="remarks_bn"
                                          slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          {{ $t('globalTrans.remarks') }} {{ $t('globalTrans.bn') }}
                                        </template>
                                      <b-form-input
                                          type="text"
                                          v-model="detailsFormData.remarks_bn"
                                          :placeholder="$t('globalTrans.remarks')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <b-col>
                                    <div class="text-right mb-2">
                                      <b-button type="submit" class="btn-sm" variant="success">{{ $t('globalTrans.add')}}</b-button>
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </fieldset>
                          </b-form>
                        </ValidationObserver>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row>
                    <b-col sm="12" v-if="checkUserAdd === 0">
                      <ValidationObserver ref="forms" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                          <div id="form" >
                            <fieldset class="p-2 w-100">
                              <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.lesson') + ' ' + $t('globalTrans.list')}}</legend>
                              <div class="p-2">
                                <b-overlay :show="loadTable">
                                  <table class="table" style="width:100%;padding: 5px">
                                      <thead class="thead">
                                        <tr>
                                          <th style="width:6%">{{ $t('globalTrans.sl_no') }}</th>
                                          <th style="width:20%">{{$t('elearning_config.course_module')}} </th>
                                          <th style="width:20%">{{$t('elearning_tim.lesson')}} </th>
                                          <th style="width:20%">{{ $t('globalTrans.select_date') }} </th>
                                          <th style="width:20%">{{ $t('globalTrans.start_time') }} </th>
                                          <th style="width:20%">{{ $t('globalTrans.end_time') }} </th>
                                          <th style="width:20%">{{ $t('globalTrans.remarks') }} </th>
                                          <th style="width:8%">{{ $t('globalTrans.action') }}</th>
                                        </tr>
                                      </thead>
                                      <tr v-for="(item, index) in data.trainer_course" :key="index">
                                        <b-td>
                                          {{ $n(index + 1) }}
                                        </b-td>
                                        <b-td>
                                          {{ getColumnName($store.state.TrainingElearning.commonObj.courseModuleList, item.course_module_id)}}
                                        </b-td>
                                        <b-td>
                                          {{ getColumnName($store.state.TrainingElearning.commonObj.courseList, item.course_management_id)}}
                                        </b-td>
                                        <b-td>
                                          <ValidationProvider name="Session Date" :vid="'session_date' + index" rules="required">
                                            <div slot-scope="{ valid, errors }">
                                              <b-form-select
                                                plain
                                                v-model="item.session_date"
                                                :options="item.trainingCalendar"
                                                id="training_date"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value=0>{{ $t('globalTrans.select') }}</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                              <div class="error invalid-feedback" role="alert">
                                                {{ errors[0] }}
                                              </div>
                                            </div>
                                          </ValidationProvider>
                                        </b-td>
                                        <b-td>
                                          <ValidationProvider name="Start Time" :vid="'start_time' + index" rules="required">
                                            <div slot-scope="{ valid, errors }">
                                              <b-form-input
                                                  type="time"
                                                  v-model="item.start_time"
                                                  :placeholder="$t('globalTrans.select_date')"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="error invalid-feedback" role="alert">
                                                {{ errors[0] }}
                                              </div>
                                            </div>
                                          </ValidationProvider>
                                        </b-td>
                                        <b-td>
                                          <ValidationProvider name="End Time" :vid="'end_time' + index" rules="required">
                                            <div slot-scope="{ valid, errors }">
                                              <b-form-input
                                                  type="time"
                                                  v-model="item.end_time"
                                                  :placeholder="$t('globalTrans.select_date')"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="error invalid-feedback" role="alert">
                                                {{ errors[0] }}
                                              </div>
                                            </div>
                                          </ValidationProvider>
                                        </b-td>
                                        <b-td>
                                          {{ $i18n.locale === 'bn' ? item.remarks_bn : item.remarks_en }}
                                        </b-td>
                                        <b-td>
                                          <span class="d-flex text-center">
                                            <b-button type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                                          </span>
                                        </b-td>
                                      </tr>
                                      <b-tr v-if="data.trainer_course.length <= 0">
                                        <td colspan="8" class="text-center">
                                            <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                                        </td>
                                      </b-tr>
                                  </table>
                                </b-overlay>
                              </div>
                            </fieldset>
                          </div>
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Office Memo" vid="office_memo" rules="required">
                                <b-form-group
                                    label-for="office_memo"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.office_memo') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="office_memo"
                                    v-model="data.office_memo"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Invitation Date" vid="invitation_date" rules="required">
                                <b-form-group
                                    label-for="invitation_date"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.select_date')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    class="fromDate"
                                    v-model="data.invitation_date"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Subject" vid="subject" rules="required">
                                <b-form-group
                                    label-for="subject"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irri_pump_main.subject') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="subject"
                                    v-model="data.subject"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Signatory Email" vid="signatory_email" rules="required">
                                <b-form-group
                                    label-for="signatory_email"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_iabm.contact_email') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="email"
                                    v-model="data.signatory_email"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Signatory info" vid="right" rules="required">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="right"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                  {{$t('globalTrans.signatory_info')}} <span class="text-danger">*</span>
                                  </template>
                                  <vue-editor
                                      id="right"
                                      v-model="data.signatory_info"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  </vue-editor>
                                  <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Description" vid="description" rules="required">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="description"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                  {{$t('elearning_iabm.copy_to')}} <span class="text-danger">*</span>
                                  </template>
                                  <vue-editor
                                      id="description"
                                      v-model="data.description"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  </vue-editor>
                                  <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <b-row class="text-right">
                            <b-col>
                              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            </b-col>
                          </b-row>
                        </b-form>
                      </ValidationObserver>
                    </b-col>
                    <b-col sm="12" v-else>
                      <h3 class="text-black text-center">{{ (currentLocale === 'bn') ? 'এই রিসোর্স পার্সন ইতিমধ্যেই যোগ করা হয়েছে। সম্পাদনা করুন.' : 'This Resource Person has already been added. Please edit.' }}</h3>
                    </b-col>
                  </b-row>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularList, invitationAddCheck, circularPublication, allTrainerData, invitationUpdate, invitationStore, invitationCircular, invitationShow } from '../../api/routes'
import flatpickr from 'flatpickr'
import { VueEditor } from 'vue2-editor'

export default {
  props: ['id'],
  components: {
    VueEditor,
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.data.org_id = this.$store.state.Auth.authUser.org_id
    }
    if (this.$route.query.circular_memo_no) {
       this.getDataCircular()
    }
    if (this.$route.query.id) {
      this.getData(this.$route.query.id)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      editShow: true,
      circularLoading: false,
      trainerLoading: false,
      valid: null,
      checkUserAdd: 0,
      // loading: false,
      loadIt: false,
      loadingCourseModule: false,
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        fiscal_year_id: 0,
        batch_no: 0,
        circular_memo_no: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        profession_type: 1,
        circular_org_id: 0,
        org_id: 0,
        personal_info_id: null,
        trainer_course: []
      },
      detailsFormData: {
        session_date: '',
        start_time: null,
        end_time: null,
        remarks_en: '',
        remarks_bn: '',
        course_module_id: 0,
        course_management_id: 0
      },
      editIndex: '',
      trainers: [],
      loadTable: false,
      officeList: [],
      courseModuleList: [],
      allCourseModuleList: [],
      courseList: [],
      allCourseList: [],
      trainerListData: [],
      trainingCategoryList: [],
      circularList: [],
      trainingCalendar: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      batchList: [],
      allBatchListData: [],
      circularListData: [],
      trainingTypeList: [],
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    orgTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
          text_en: 'Non-Goverment Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ]
    },
    trainerList: function () {
      const listObject = this.trainerListData
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    }
  },
  watch: {
    'data.circular_org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'data.training_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.trainingCategoryList = this.getCategoryList(newVal)
      } else {
        this.trainingCategoryList = []
      }
    },
    'data.training_category_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.trainingTitleList = this.getTrainingTitleList(newVal)
      } else {
        this.trainingTitleList = []
      }
    },
    'data.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'data.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.data.batch_no = allBatchListData.batch_no
        }
      }
    },
    'data.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.data.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
    },
    'data.profession_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === 2 || newVal === 3) {
          this.data.org_id = 0
          this.trainerListData = []
          this.getOrgTrainerList()
        } else {
          if (this.data.org_id) {
            this.getOrgTrainerList()
          }
        }
      }
    },
    'data.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOrgTrainerList()
      }
    },
    'data.personal_info_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.$route.query.id) {
        } else {
          this.getCheckAddData()
        }
        this.getCircularWiseDataInfo()
      }
    },
    'detailsFormData.course_module_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.courseList = this.getCourseList(newVal)
      } else {
        this.courseList = []
      }
    }
  },
  methods: {
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === parseInt(orgId))
      }
      return type
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.data.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.data.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.data.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    deleteItem (index) {
      this.data.trainer_course.splice(index, 1)
    },
    async getCheckAddData () {
      this.loadIt = true
      const params = {
        fiscal_year_id: this.data.fiscal_year_id,
        circular_memo_no: this.data.circular_memo_no,
        personal_info_id: this.data.personal_info_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, invitationAddCheck, params)
      if (result.success) {
        this.checkUserAdd = 1
        this.loadIt = false
      } else {
        this.checkUserAdd = 0
        this.loadIt = false
      }
    },
    async getData (id) {
      this.loadIt = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, invitationShow + '/' + id)
      if (result.success) {
        this.data = result.data
        this.getCircularList()
        this.loadIt = false
      } else {
        this.loadIt = false
      }
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.data.fiscal_year_id,
        training_type_id: this.data.training_type_id,
        training_category_id: this.data.training_category_id,
        training_title_id: this.data.training_title_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
        this.allBatchListData = []
      } else {
        this.allBatchListData = result.batchList
        this.batchInfo()
        this.circluarList()
      }
      this.circularLoading = false
    },
    async addInv () {
      const detailsData = this.detailsFormData
      const trainerCourse = this.data.trainer_course
      trainerCourse.push(detailsData)
      const listData = trainerCourse.map(item => {
        const customItem = {}
        customItem.trainingCalendar = this.trainingCalendar
        return Object.assign({}, item, customItem)
      })
      this.data.trainer_course = listData
      this.detailsFormData = {
        session_date: '',
        start_time: null,
        end_time: null,
        course_module_id: 0,
        course_management_id: 0
      }
      this.$refs.form.reset()
    },
    async saveUpdate () {
      this.loadIt = true
      let result = null
      if (this.data.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${invitationUpdate}/${this.data.id}`, this.data)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, invitationStore, this.data)
      }
      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.data.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/iabm/invitation?circular_memo_no=' + this.data.circular_memo_no + '&batch_no=' + this.data.batch_no)
        // this.$bvModal.hide('modal-form')
      } else {
        this.loadIt = false
        this.$refs.form.setErrors(result.errors)
      }
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
          if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        } else {
          return obj.text_en
        }
      } else {
          return ''
      }
    },
    async getOrgTrainerList () {
      this.trainerLoading = true
      let orgiId = 0
      if (this.$store.state.Auth.activeRoleId !== 1) {
        orgiId = this.$store.state.Auth.authUser.org_id
      }
      const params = {
        org_id: this.data.org_id,
        profession_type: this.data.profession_type,
        applied_org_id: orgiId
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, allTrainerData, params)
      if (!result.success) {
        this.trainerListData = []
      } else {
        this.trainerListData = result.data
      }
      this.trainerLoading = false
    },
    async getDataCircular () {
      this.loadIt = true
      const params = {
        table: 'iab_circular_publications',
        circular_memo_no: this.$route.query.circular_memo_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      if (result.success) {
        this.data.fiscal_year_id = result.data.fiscal_year_id
        this.data.circular_org_id = result.data.org_id
        this.data.training_type_id = result.data.training_type_id
        this.data.training_category_id = result.data.training_category_id
        this.data.training_title_id = result.data.training_title_id
        this.data.circular_memo_no = result.data.circular_memo_no
        this.data.batch_no = result.data.batch_no
        this.loadIt = false
      }
    },
    async getCircularWiseDataInfo () {
      this.loadTable = true
      const params = {
        fiscal_year_id: this.data.fiscal_year_id,
        org_id: this.data.org_id,
        circular_memo_no: this.data.circular_memo_no,
        personal_info_id: this.data.personal_info_id,
        batch_no: this.data.batch_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, invitationCircular, params)
      if (!result.success) {
        this.trainingCalendar = []
      } else {
        this.trainingCalendar = result.data.trainingCalendar
        if (result.data.course_module_id.length) {
          const moduleList = []
          result.data.course_module_id.map((obj, index) => {
            if (obj) {
              const isThere = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(module => parseInt(module.value) === parseInt(obj))
              if (typeof isThere !== 'undefined') {
                moduleList.push(isThere)
              }
            }
          })
          this.allCourseModuleList = moduleList
          this.courseModuleList = this.getCourseModuleList(this.data.training_title_id)
        }
        if (result.data.course_management_id.length) {
          const courseList = []
          result.data.course_management_id.map((obj, index) => {
            if (obj) {
              const isThere = this.$store.state.TrainingElearning.commonObj.courseList.find(module => parseInt(module.value) === parseInt(obj))
              if (typeof isThere !== 'undefined') {
                courseList.push(isThere)
              }
            }
          })
          this.allCourseList = courseList
        }
      }
      this.loadTable = false
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getCourseList (typeId) {
       const courseList = this.allCourseList.filter(item => item.status === 1)
       if (typeId) {
         return courseList.filter(item => item.course_module_id === typeId)
       }
       return courseList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    getCourseModuleList (trainingTitleId) {
      const courseModuleList = this.allCourseModuleList.filter(item => item.status === 1)
      if (trainingTitleId) {
        return courseModuleList.filter(item => item.training_title_id === trainingTitleId)
      }
      return courseModuleList
    }
  }
}
</script>
